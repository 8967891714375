module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/gatsby-plugin-gdpr-cookies-sensai/gatsby-browser.js'),
      options: {"plugins":[],"waitForInitalization":true,"googleTagManager":{"trackingId":"GTM-5SVJDGR","cookieName":"CookieConsent-s","allowAdFeatures":true,"routeChangeEvent":"routeChange"},"luckyOrange":{"cookieName":"CookieConsent-s"},"hotjar":{"hjid":"2436141","hjsv":"6","cookieName":"CookieConsent-s"},"googleAnalytics":{"trackingId":"G-CSQ9HHM1YX","allowAdFeatures":true,"cookieName":"CookieConsent-s"},"facebookPixel":{"pixelId":"403966576847034","cookieName":"CookieConsent-s"},"environments":["development","production"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"sens.ai","short_name":"sens.ai","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"88866c32636861c86de22de88dbd06b6"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
