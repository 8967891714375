import { useStaticQuery, graphql } from "gatsby"
import { Product } from "../ts/interfaces"

export const useProducts = () => {
  const { strapi } = useStaticQuery(
    graphql`
      query {
        strapi {
          products {
            id
            name
            stripeId
            stripeTestId
            slug
            subHeading
            description
            shortDescription
            featuredImage {
              url
              alternativeText
              imageFile {
                childImageSharp {
                  gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
                }
              }
            }
            CAD_List_Price
            CAD_Discount
            USD_List_Price
            USD_Discount
            isBilledMonthly
            isBilledYearly
            depositHeading
            priceHeading
            inventoryBreakpoints {
              id
              breakpointText
              breakpointStart
              breakpointEnd
              textColor
            }
            gallery {
              url
              imageFile {
                childImageSharp {
                  gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    `
  )
  return strapi.products as [Product]
}
